import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css'],
})
export class SuccessComponent implements OnInit {

  fieldTextType: boolean = false;
  signupRequest: object;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  public successForm: FormGroup = new FormGroup({
  })

  public is_valid: boolean = true;
  private readonly notifier: NotifierService;

  disabled_button: boolean = false;

  constructor(
    notifierService: NotifierService
    ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  async goToContalink() {
    window.location.href = "https://app.contalink.com";
  }

  get successFormControl() {
    return this.successForm.controls;
  }

}