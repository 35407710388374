import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterPhoneService {


  private url: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend/registerphone';
  private api_token: string = 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G';

  constructor(private http: HttpClient) {

  }

  public registerCustomerPhone(registerPhone: RegisterPhone): Observable<any> {

    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);

    return this.http.post(this.url, registerPhone, {
      headers: headers
    });
  }
}
export interface RegisterPhone {
    phonenumber: string
}