import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SendSignupDataService {


  private url: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend';
  private api_token: string = 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G';

  constructor(private http: HttpClient) {

  }

  public signupCustomer(signupData: SignupData): Observable<any> {

    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);

    return this.http.post(this.url, signupData, {
      headers: headers
    });
  }
}
export interface SignupData {
  name: string,
  email: string,
  userpass: string,
  phone: string,
  razon_social: string,
  rfc: string,
  type_client: string,
  accountants_number: string,
  customer_source: string,
  confirmationcode: string
}