<notifier-container></notifier-container>
<section>
  <div class="row" [formGroup]="successForm">
    <div class="contact-form-bckgd">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="contact-bg-img-text">Bienvenido a Contalink</p>
        <div class="contact-form form-inline" role="form">
          <div class="row">
            <p for="confirmation_code" class="label-contact-field">Tu registro ha sido exitoso.</p>
            <br>
            <p for="confirmation_code" class="label-contact-field">Haz click en el siguiente botón para ir a la página de inicio de sesión de Contalink e ingresa con el correo y la contraseña que nos acabas de proporcionar.</p>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button id="confirm_phone_form" class="contact-button" (click)="goToContalink()"
                [disabled]="disabled_button">Iniciar sesión en Contalink</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>