<notifier-container></notifier-container>
<ngx-spinner></ngx-spinner>

<div>
  <div class="hero">
    <div class="lite-logo">
      <img src="assets/contalink-lite-logo.png" alt="Contalink Lite">
    </div>
    
    <div class="login-form-container">
      <div class="titles">
        <h1 class="subtitle">
          Una <span class="text-gradient-contalink-primary">mejor y más rápida</span> alternativa para descargar los CFDIs de tus clientes en la nube. <br> ¡GRATUITA!
        </h1>
        <h2 class="text">
          Almacenamiento ilimitado de CFDI's, descarga automática del SAT y exportación de reportes para tus clientes
        </h2>
      </div>
    </div>
  </div>

  <div class="form-container" id="registro">
    <div class="row" [formGroup]="signupForm">
      <div class="signup-form shadow contact-form-bckgd">
        <div class="col-12">
          <p class="contact-bg-img-text">Regístrate</p>
          <div class="contact-form form-inline" role="form">
            <div class="row">
              <label for="name" class="label-contact-field">Nombre completo *</label>
              <br>
              <input class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-field" id="name" formControlName="name"
                [ngClass]="{'': signupFormControl.name.valid && is_valid, 'required': signupFormControl.name.invalid && !is_valid }">
            </div>
            <div class="row">
              <br>
              <label for="user_email" class="label-contact-field">Correo electrónico *</label>
              <br>
              <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" id="user_email" type="text" formControlName="email"
                [ngClass]="{'': signupFormControl.email.valid && is_valid, 'required': signupFormControl.email.invalid && !is_valid }">
            </div>
            <div class="row">
              <br>
              <label for="user_password" class="label-contact-field">Password *</label>
              <br>
              <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pwd-field" [type]="fieldTextType ? 'text' : 'password'" id="user_password"  formControlName="userpass"
                [ngClass]="{'': signupFormControl.userpass.valid && is_valid, 'required': signupFormControl.userpass.invalid && !is_valid }">
                <mat-icon class="password-toggle" (click)="toggleFieldTextType()">{{fieldTextType ? 'visibility' : 'visibility_off'}}</mat-icon>
            </div>
            <div class="row">
              <br>
              <label for="client_phone" class="label-contact-field">Celular *</label>
              <br>
              <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="tel" id="client_phone" formControlName="phone"
                [ngClass]="{'': signupFormControl.phone.valid && is_valid, 'required': signupFormControl.phone.invalid && !is_valid }">
            </div>
            <div class="row">
              <br>
              <label for="razon_social" class="label-contact-field">Razón Social a descargar * </label>
              <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="text" id="razon_social" formControlName="razon_social"
                [ngClass]="{'': signupFormControl.razon_social.valid && is_valid, 'required': signupFormControl.razon_social.invalid && !is_valid }">
            </div>
            <div class="row">
              <br>
              <label for="rfc_client" class="label-contact-field">RFC a descargar *</label>
              <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="text" formControlName="rfc" id="rfc_client"
                [ngClass]="{'': signupFormControl.rfc.valid && is_valid, 'required': signupFormControl.rfc.invalid && !is_valid }">
                <br>
                <label for="rfc_client" class="label-contact-field">**  Esta será la primera empresa a descargar. Después podrás agregar tantas como necesites</label>
                <br>
            </div>
            <div class="row">
              <br>
              <label class="label-contact-field" for="type_client">¿Que opción te describe mejor? *</label>
              <br>
              <select name="type_client" id="type_client" class="custom-select">
                <optgroup class="label-contact-field">
                  <option value=''></option>
                  <option value="DC">Soy un contador dentro de un despacho contable</option>
                  <option value="DCPYME">Trabajo en el departamento contable de una empresa</option>
                  <option value="PYME">Soy una PYME</option>
                  <option value="PI">Soy un profesionista independiente</option>
                </optgroup>>
              </select>
            </div>
            <div class="row">
              <br>
              <label for="accountants_number" class="label-contact-field">Número de contadores o auxiliares, incluyendo a usted. *</label>
              <input class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field" type="number" formControlName="accountants_number"
                [ngClass]="{'': signupFormControl.accountants_number.valid && is_valid, 'required': signupFormControl.accountants_number.invalid && !is_valid }">
            </div>
            <div class="row">
              <br>
              <label class="label-contact-field" for="customer_source">¿Cómo te enteraste de nosotros? *</label>
              <br>
              <select name="customer_source" id="customer_source" class="custom-select">
                <optgroup class="label-contact-field">
                  <option value=''></option>
                  <option value="GOOGLESEARCH">Búsqueda en Google</option>
                  <option value="FB ADS">Anuncio en Facebook</option>
                  <option value="GOOGLE ADS">Anuncio en otro sitio Web</option>
                  <option value="IMCP">IMCP</option>
                  <option value="EL CONTRIBUYENTE">El Contribuyente</option>
                  <option value="FORBES">Forbes</option>
                  <option value="REFERENCIA">Referencia de conocido / colega</option>
                  <option value="OTRO">Otro</option>
                </optgroup>>
              </select>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="send_button_container">
                  <button id="send_contact_form" class="contact-button" (click)="enviar()"
                    [disabled]="disabled_button">Enviar</button>
                </div>
              </div>
            </div>
            <div class="row mt-10">
              <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <a class="privacy-text" routerLink="/aviso-de-privacidad">Aviso de privacidad</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="benefits">
    <div class="container">
      <div class="benefits-grid">
        <div class="benefits-icons-container">
          <div>
            <img src="assets/iconos/benefit-1.png" alt="Icono del beneficio uno" loading="lazy">
          </div>
          <div>
            <img src="assets/iconos/benefit-2.png" alt="Icono del beneficio dos" loading="lazy">
          </div>
          <div>
            <img src="assets/iconos/benefit-3.png" alt="Icono del beneficio tres" loading="lazy">
          </div>
        </div>
        <div class="benefits-text">
          <h2 class="subtitle">
            Conoce los beneficios
          </h2>
          <p class="text">
            Contalink Lite es una herramienta gratuita  que podrás aprovechar para mejorar tus tiempos de trabajo. Y con la mejor interfaz de usuario.
          </p>

          <div class="benefits-list">
            <span class="perk"><div class="blue-circle"></div> Ilimitado</span>
            <span class="perk"><div class="blue-circle"></div> Descarga automática</span>
            <span class="perk"><div class="blue-circle"></div> Exportación de reportes en XLS</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="free">
    <div class="container">
      <div class="why-free">
        <div class="col d-flex flex-column justify-content-center p-3">
          <h2 class="subtitle small-subtitle">
            RFC Contribuyentes ilimitados y almacenamiento ilimitado de CFDIs
          </h2>
          <p class="text">
            Esta versión gratis <b>no tiene límite de almacenamiento</b>. Organiza dentro del sistema todos los contribuyentes que gustes y almacena todas sus CFDIs sin preocuparte.
          </p>
        </div>

        <div class="col margin-bottom-120">
          <img src="assets/img1.png" alt="Interfaz de Contalink Lite" loading="lazy">
        </div>

        <div class="col p-3 show-mobile">
          <h2 class="subtitle small-subtitle">
            Descarga automática del SAT vía FIEL
          </h2>
          <p class="text">
            Consulta la información de tus contribuyentes <b>directamente desde el SAT en tiempo real</b>. <br> Todo un despacho en la nube.
          </p>
        </div>
        
        <div class="col margin-bottom-120">
          <img src="assets/img3.png" alt="Interfaz de Contalink Lite" loading="lazy">
        </div>

        <div class="col p-3 hide-mobile">
          <h2 class="subtitle small-subtitle">
            Descarga automática del SAT vía FIEL
          </h2>
          <p class="text">
            Consulta la información de tus contribuyentes <b>directamente desde el SAT en tiempo real</b>. <br> Todo un despacho en la nube.
          </p>
        </div>

        <div class="col p-3">
          <h2 class="subtitle small-subtitle">
            Exportación de reportes en XLS
          </h2>
          <p class="text">
            <b>Exporta la información que manejas a Excel</b> para almacenarla de manera local, trabajarla o enviarla.
          </p>
        </div>
        <div class="col">
          <img src="assets/img2.png" alt="Interfaz de Contalink Lite" loading="lazy">
        </div>
      </div>
      <div style="display: flex; align-items: center; justify-content: center; padding: 32px 0;">
        <a href="#registro" class="button-primary">
          Registrarme
        </a>
      </div>
    </div>
  </div>

  <div class="last-information">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="text-center" style="color: white; margin-bottom: 32px;">
            Contalink Lite es un producto de:
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 information-img">
          <img src="assets/contalink-laptop.png" alt="Computadora con una imagen representativa de Contalink" loading="lazy" class="img-fluid">
        </div>
        <div class="col-12 col-lg-6 information-text">
          <div>
            <img src="assets/contalink-icon.png" alt="Icono de Contalink" loading="lazy" class="img-fluid">
          </div>
          <h2 class="title">
            <span class="text-gradient-contalink-primary">Contalink:</span> Sistema completo de contabilidad en la nube para Contadores
          </h2>
          <ul class="text">
            <li>Contabilidad </li>
            <li>Nómina </li>
            <li>Facturación </li>
            <li>Bancos </li>
            <li>Activo Fijo </li>
            <li>Contabilidad Electrónica </li>
            <li>Impuestos </li>
            <li>Auditoria</li>
          </ul>
          <a href="https://www.contalink.com?utm_source=Contalink&utm_medium=Contalink-One&utm_campaign=Landing" target="_blank" rel="noopener noreferrer" class="button-primary">
            Más información
          </a>
        </div>
      </div>
    </div>
  </div>

</div>

<section>
  
</section>