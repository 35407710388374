import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  COOKIES_LIFETIME_DAYS = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365); // Almost 1 year (dependening biciest years)
  title = 'contalink-com';

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.readUtmCookies()

    $(document).ready(function() {

      $('.func-item').click(function() {
        var section = $(this).attr("func");
        setClass($(this));
        changeSection(section);
      });

      let setClass = function(that) {
        $(".func-item").removeClass("func-active");
        that.addClass("func-active")
      }

      let changeSection = function(section) {
        $(".func-section").removeClass("visible");
        $(".func-section").addClass("hidden");
        $('#' + section).addClass('visible');
        $('#' + section).removeClass('hidden');
      }

      $('.thumb-video').click(function(event) {
        event.preventDefault();
        var theModal = $(this).data("target"),
          videoSRC = $(this).attr("data-theVideo"),
          videoSRCauto = videoSRC + "?autoplay=1";
        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal + ' button.close').click(function() {
          $(theModal + ' iframe').attr('src', videoSRC);
        });
      });
   

    });
  }

  setCookie(name: string, content: any, expire?: number | Date) {
    this.cookieService.set(name, content, expire);
  }

  getCookie(name: string) {
    return this.cookieService.get(name);
  }

  readUtmCookies() {
    this.route.queryParams.subscribe((params) => {
      const { utm_medium, utm_source, utm_campaign } = params;

      //   Set UTM Cookies to most recent data
      if (utm_medium) this.setCookie('utm_medium', utm_medium);
      if (utm_source) this.setCookie('utm_source', utm_source);
      if (utm_campaign) this.setCookie('utm_campaign', utm_campaign);

      //   If isn't the first time user visit Contalink, then keeps the UTM data of where the user comes
      if (!this.getCookie('first_utm')) {
        let first_utm = '';
        if (utm_medium) first_utm += `Medium: ${utm_medium} - `;
        if (utm_source) first_utm += `Source: ${utm_source} - `;
        if (utm_campaign) first_utm += `Campaign: ${utm_campaign}`;

        this.setCookie('first_utm', first_utm, this.COOKIES_LIFETIME_DAYS);
      }

      // Also keep the date user's first visit
      if (!this.getCookie('first_visit_date')) {
        const date = new Date();

        this.setCookie(
          'first_visit_date',
          `${date.toLocaleString()}`,
          this.COOKIES_LIFETIME_DAYS
        );
      }
    });
  }
}
